import React from "react";

const Categorylist = () => {
  return (
    <div className="w-[85%] mx-auto">
      <ul className="flex text-xl font-bold gap-10 border-b-2 text-gray-500">
        <li className="hover:text-black hover:border-b-2 cursor-pointer border-black pb-5">View all</li>
        <li className="hover:text-black hover:border-b-2 cursor-pointer border-black pb-5">Design</li>
        <li className="hover:text-black hover:border-b-2 cursor-pointer border-black pb-5">Product</li>
        <li className="hover:text-black hover:border-b-2 cursor-pointer border-black pb-5">Development</li>
      </ul>
    </div>
  );
};

export default Categorylist;
