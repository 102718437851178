import React, { useState } from "react";
import { RiMailSendLine } from "react-icons/ri";
import styles from "../../styles/styles";

const Contact = () => {
  const [status, setStatus] = useState("Submit");
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const submitEmail = async (e) => {
    e.preventDefault();
    console.log({ mailerState });
    setStatus("Sending...");
    const response = await fetch("https://server-352b.onrender.com/send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        setStatus("Submit");
        const resData = await res;
        console.log(resData);
        if (resData.status === "success") {
          alert("Message Sent");
        } else if (resData.status === "fail") {
          alert("Message failed to send");
        }
      })
      .then(() => {
        setMailerState({
          email: "",
          name: "",
          phone: "",
          message: "",
        });
      });
  };

  return (
    <section>
      <div className={`${styles.container}`}>
        <div className="px-6 mb-8">
          <h1 className="text-headingColor font-[600] text-[1.5rem] mb-5 text-center">
            Contact us
          </h1>
          <p className="text-justify text-sm mb-6">
          To contact us, fill out the form below and send it. We will contact you as soon as possible via phone or email. Then you will be sure that you have made the right choice. Because we are committed to living up to your plans.
          </p>
          <div className="md:flex justify-between items-center">
            <div className="w-full md:w-1/2 h-[300px] sm:h-[450px]">
              <iframe
                title="google-maps"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCDm1PlbBJ22IlwSLUpQvYPbnlmCG7-ru8
              &q=Earthcorp,Faizabad+IN"
                className="border-0 w-full h-full"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div className="w-full mt-8 md:mt-0 md:w-1/2 sm:h-[450px] lg:flex items-center lg:pl-8">
              <form className="w-full space-y-4" onSubmit={submitEmail}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      onChange={handleStateChange}
                      name="name"
                      value={mailerState.name}
                      autoComplete="name"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      onChange={handleStateChange}
                      name="email"
                      value={mailerState.email}
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      onChange={handleStateChange}
                      name="phone"
                      value={mailerState.phone}
                      autoComplete="phone"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Your Query
                  </label>
                  <div className="mt-1">
                    <textarea
                      type="text"
                      onChange={handleStateChange}
                      name="message"
                      value={mailerState.message}
                      rows={3}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                    />
                  </div>
                </div>

                <button type="submit" className={`${styles.button} mt-4`}>
                  <RiMailSendLine />
                  {status}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
