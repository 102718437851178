import React, { useState } from "react";
import styles from "../styles/styles";
import { RiMailSendLine } from "react-icons/ri";

const ContactModal = ({ closeModal }) => {
  const [status, setStatus] = useState("Submit");
  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const submitEmail = async (e) => {
    e.preventDefault();
    console.log({ mailerState });
    setStatus("Sending...");
    const response = await fetch("https://server-352b.onrender.com/send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        setStatus("Submit");
        const resData = await res;
        console.log(resData);
        if (resData.status === "success") {
          alert("Message Sent");
        } else if (resData.status === "fail") {
          alert("Message failed to send");
        }
      })
      .then(() => {
        setMailerState({
          email: "",
          name: "",
          phone: "",
          message: "",
        });
      });
  };

  return (
    <div className="w-full h-full fixed top-0 left-0 z-10 bg-headingColor bg-opacity-40">
      <div className="w-11/12 md:max-w-[600px] md:w-400 absolute top-1/2 left-1/2 z-10 bg-white transform -translate-x-1/2 -translate-y-1/2 p-8">
        <button
          onClick={() => closeModal(false)}
          className="w-[2rem] h-[2rem] bg-[white] absolute top-[1.7rem] right-[1rem] text-[30px] flex items-center justify-center rounded-[3px] leading-0 cursor-pointer"
        >
          &times;
        </button>

        <div>
          <h2 className="text-2xl text-headingColor font-[700] my-2">
            Fill for Quick Contact
          </h2>

          <div className="w-full md:mt-0 items-center py-4">
            <form className="w-full space-y-4" onSubmit={submitEmail}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    autoComplete="name"
                    value={mailerState.name}
                    onChange={handleStateChange}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={mailerState.email}
                    onChange={handleStateChange}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    autoComplete="phone"
                    value={mailerState.phone}
                    onChange={handleStateChange}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your Query
                </label>
                <div className="mt-1">
                  <textarea
                    type="text"
                    name="message"
                    rows={3}
                    value={mailerState.message}
                    onChange={handleStateChange}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-300 sm:text-sm"
                  />
                </div>
              </div>

              <button
                type="submit"
                className={`${styles.button} mt-4`}
              >
                <RiMailSendLine />
                {status}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
