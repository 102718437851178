import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import TermsConditions from "../components/Route/TermsConditions";
import Helmet from "../components/Helmet/Helmet";

const TermsPage = () => {
  return (
    <>
      <Helmet title={"Terms & Conditions"}>
        <Header />
        <TermsConditions />
        <Footer />
      </Helmet>
    </>
  );
};

export default TermsPage;
