import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Contact from "../components/Route/Contact";
import Helmet from "../components/Helmet/Helmet";

const ContactPage = () => {
  return (
    <>
      <Helmet title={"Contact us"}>
        <Header activeHeading={5} />
        <Contact />
        <Footer />
      </Helmet>
    </>
  );
};

export default ContactPage;
