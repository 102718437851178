import React, { useState } from "react";
import logoImg from "../../assets/images/logo.png";
import { RxDashboard } from "react-icons/rx";
import { RiMenu4Line, RiCloseFill } from "react-icons/ri";
import { HiOutlineShoppingBag } from "react-icons/hi";
import Navbar from "./Navbar";
import { navItems } from "../../static/data";

const Header = ({ activeHeading }) => {
  const [toggle, setToggle] = useState(false);
  const [active, setActive] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 50) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <header
      className={`${
        active === true
          ? "fixed shadow-md top-0 left-0 z-10 bg-white p-2 bg-opacity-95"
          : null
      } bg-white w-full p-2 border-b-[1px]`}
    >
      <div className="flex items-center justify-between max-w-[1240px] mx-auto py-2">
        <a href="/">
          <div className="flex items-center gap-[10px]">
            <span className="w-[36px] h-[auto] flex items-center justify-center">
              <figure>
                <img src={logoImg} alt="logo" />
              </figure>
            </span>
            <div className="leading-[20px]">
              <h2 className="text-xl text-headingColor font-[700]">
                Earthcorp
              </h2>
              <p className="text-headingColor text-[14px] font-[500]">
                IT Support & Services
              </p>
            </div>
          </div>
        </a>

        <ul className="hidden md:flex gap-8">
          {navItems &&
            navItems.map((item, index) => (
              <li key={index}>
                <a
                  href={item.url}
                  className={`${
                    active === index + 1
                      ? "text-primaryColor"
                      : "text-primaryColor hover:text-headingColor text-[16px] font-[600]"
                  }`}
                >
                  {item.title}
                </a>
              </li>
            ))}
        </ul>

        <ul
          className={`duration-300 md:hidden w-[230px] z-10 h-screen fixed bg-white top-[80px] bg-opacity-95 shadow-2xl ${
            toggle ? "left-[0] p-3 leading-[45px]" : "left-[-100%]"
          }`}
        >
          <Navbar active={activeHeading} />
        </ul>

        <div className="flex items-center gap-4">
          <span className="text-2xl text-headingColor cursor-pointer">
            <HiOutlineShoppingBag />
          </span>
          <a href="https://dashboard.earthcorp.in">
            <span className="text-2xl text-headingColor cursor-pointer">
              <RxDashboard />
            </span>
          </a>

          {toggle ? (
            <RiCloseFill
              onClick={() => setToggle(!toggle)}
              className="md:hidden block text-2xl text-headingColor cursor-pointer"
            />
          ) : (
            <RiMenu4Line
              onClick={() => setToggle(!toggle)}
              className="md:hidden block text-2xl text-headingColor cursor-pointer"
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
