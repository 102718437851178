import React from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../../assets/images/404.png";

const Error = () => {
  const navigate = useNavigate();
  
  return (
    <div
      onClick={() => navigate(-1)}
      draggable={false}
      className="min-h-[100vh] flex justify-center items-center cursor-pointer"
    >
      <div class="p-6 max-w-sm mx-auto bg-white shadow-lg flex items-center space-x-4">
        <div class="shrink-0">
          <figure>
            <img src={notFound} alt="error" />
          </figure>
        </div>
        <div>
          <div class="text-xl font-medium text-black">Error 404</div>
          <p class="text-slate-500">Page not found!</p>
        </div>
      </div>
    </div>
  );
};

export default Error;
