import React from "react";
import styles from "../../styles/styles";

const Privacy = () => {
  return (
    <section>
      <div className={`${styles.container}`}>
        <div className="px-6 mb-8">
          <h1 className="text-headingColor font-[600] text-[1.5rem] mb-5 text-center">
            Privacy Policy
          </h1>
          <p className="text-justify text-sm mb-4 italic">
            This document is published and shall be construed in accordance with
            the provisions of the Information Technology (reasonable security
            practices and procedures and sensitive personal data of information)
            rules, 2011 under Information Technology Act, 2000; that require
            publishing of the Privacy Policy for collection, use, storage and
            transfer of sensitive personal data or information.
          </p>
          <p className="text-justify text-sm">
            Please read this Privacy Policy carefully by using the Website, you
            indicate that you understand, agree and consent to this Privacy
            Policy. If you do not agree with the terms of this Privacy Policy,
            please do not use this Website.
          </p>
          <p className="text-justify text-sm">
            You may not sell or modify the content of this Website or reproduce,
            display, publicly perform, distribute, or otherwise use the
            materials in any way for any public or commercial purpose without
            the respective organisation's or entity's written permission.
          </p>
          <p className="text-justify text-sm">
            By providing us your Information or by making use of the facilities
            provided by the Website, You hereby consent to the collection,
            storage, processing and transfer of any or all of Your Personal
            Information and Non-Personal Information by us as specified under
            this Privacy Policy. You further agree that such collection, use,
            storage and transfer of Your Information shall not cause any loss or
            wrongful gain to you or any other person.
          </p>
          <h3 className="text-headingColor font-[600] my-3 text-md text-justify uppercase">
            User informations
          </h3>
          <p className="text-justify text-sm">
            To avail certain services on our Websites, users are required to
            provide certain information for the registration process namely: -
            a) your name, b) email address, c) sex, d) age, e) PIN code, f)
            credit card or debit card details g) medical records and history h)
            sexual orientation, i) biometric information, j) password etc., and
            / or your occupation, interests, and the like. The Information as
            supplied by the users enables us to improve our sites and provide
            you the most user-friendly experience.
          </p>
          <p className="text-justify text-sm">
            All required information is service dependent and we may use the
            above said user information to, maintain, protect, and improve its
            services (including advertising services) and for developing new
            services Such information will not be considered as sensitive if it
            is freely available and accessible in the public domain or is
            furnished under the Right to Information Act, 2005 or any other law
            for the time being in force.
          </p>
          <h3 className="text-headingColor font-[600] my-3 text-md text-justify uppercase">
            Cookies
          </h3>
          <p className="text-justify text-sm">
            To improve the responsiveness of the sites for our users, we may use
            "cookies", or similar electronic tools to collect information to
            assign each visitor a unique, random number as a User Identification
            (User ID) to understand the user's individual interests using the
            Identified Computer. Unless you voluntarily identify yourself
            (through registration, for example), we will have no way of knowing
            who you are, even if we assign a cookie to your computer. The only
            personal information a cookie can contain is information you supply
            (an example of this is when you ask for our Personalised Horoscope).
            A cookie cannot read data off your hard drive. Our advertisers may
            also assign their own cookies to your browser (if you click on their
            ads), a process that we do not control.
          </p>
          <p className="text-justify text-sm">
            Our web servers automatically collect limited information about your
            computer's connection to the Internet, including your IP address,
            when you visit our site. (Your IP address is a number that lets
            computers attached to the Internet know where to send you data --
            such as the web pages you view.) Your IP address does not identify
            you personally. We use this information to deliver our web pages to
            you upon request, to tailor our site to the interests of our users,
            to measure traffic within our site and let advertisers know the
            geographic locations from where our visitors come.
          </p>
          <h3 className="text-headingColor font-[600] my-3 text-md text-justify uppercase">
            Links to the other sites
          </h3>
          <p className="text-justify text-sm">
            Our policy discloses the privacy practices for our own web site
            only. Our site provides links to other websites also that are beyond
            our control. We shall in no way be responsible in way for your use
            of such sites.5.
          </p>
          <h3 className="text-headingColor font-[600] my-3 text-md text-justify uppercase">
            Information sharing
          </h3>
          <p className="text-justify text-sm">
            We shares the sensitive personal information to any third party
            without obtaining the prior consent of the user in the following
            limited circumstances:
          </p>
          <p className="text-justify text-sm">
            <em>(b)</em> We proposes to share such information within its group
            companies and officers and employees of such group companies for the
            purpose of processing personal information on its behalf. We also
            ensure that these recipients of such information agree to process
            such information based on our instructions and in compliance with
            this Privacy Policy and any other appropriate confidentiality and
            security measures.
          </p>
          <h3 className="text-headingColor font-[600] my-3 text-md text-justify uppercase">
            Information security
          </h3>
          <p className="text-justify text-sm">
            We take appropriate security measures to protect against
            unauthorized access to or unauthorized alteration, disclosure or
            destruction of data. These include internal reviews of our data
            collection, storage and processing practices and security measures,
            including appropriate encryption and physical security measures to
            guard against unauthorized access to systems where we store personal
            data.
          </p>
          <p className="text-justify text-sm">
            All information gathered on our Website is securely stored within
            our controlled database. The database is stored on servers secured
            behind a firewall; access to the servers is password-protected and
            is strictly limited. However, as effective as our security measures
            are, no security system is impenetrable. We cannot guarantee the
            security of our database, nor can we guarantee that information you
            supply will not be intercepted while being transmitted to us over
            the Internet. And, of course, any information you include in a
            posting to the discussion areas is available to anyone with Internet
            access.
          </p>
          <p className="text-justify text-sm">
            However the internet is an ever evolving medium. We may change our
            Privacy Policy from time to time to incorporate necessary future
            changes. Of course, our use of any information we gather will always
            be consistent with the policy under which the information was
            collected, regardless of what the new policy may be.
          </p>
          <h3 className="text-headingColor font-[600] my-3 text-md text-justify uppercase">
            Grievance redressal
          </h3>
          <p className="text-justify text-sm">
            Redressal Mechanism: Any complaints, abuse or concerns with regards
            to content and or comment or breach of these terms shall be
            immediately informed to the designated Grievance Officer as
            mentioned below via in writing or through email signed with the
            electronic signature to Mr. Suraj Singh. ("Grievance Officer").
          </p>{" "}
          <br />
          --
          <p className="text-justify text-sm">
            Mr. Suraj Singh (Grievance Officer) <br />
            <a href="/" className="flex text-headingColor font-[400]">
              Earthcorp
            </a>
            Ayodhya, Uttar Pradesh, India <br />
            Email :{" "}
            <a href="mailto:grievance@earthcorp.in">
              grievance@earthcorp.in
            </a>{" "}
            <br />
            Phone : +91 80900 00782
          </p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
