import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Helmet from "../components/Helmet/Helmet";
import Opportunity from "../components/Route/Opportunity";

const OpportunityPage = () => {
  return (
    <>
      <Helmet title={"Opportunity"}>
        <Header activeHeading={7} />
        <Opportunity />
        <Footer />
      </Helmet>
    </>
  );
};

export default OpportunityPage;
