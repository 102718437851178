import React from "react";
import Error from "../components/Route/Error";
import Helmet from "../components/Helmet/Helmet";

const ErrorPage = () => {
  return (
    <Helmet title={"Page not found"}>
      <Error />
    </Helmet>
  );
};

export default ErrorPage;
