import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Services from "../components/Route/Services";
import Helmet from "../components/Helmet/Helmet";

const ServicesPage = () => {
  return (
    <>
      <Helmet title={"Services"}>
        <Header activeHeading={4} />
        <Services />
        <Footer />
      </Helmet>
    </>
  );
};

export default ServicesPage;
