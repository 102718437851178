import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Hero from "../components/Route/Hero";
import Helmet from "../components/Helmet/Helmet";
import Companies from "../components/Route/Companies";

const HomePage = () => {
  return (
    <>
      <Helmet title={"Home"}>
        <Header activeHeading={1} />
        <Hero />
        <Companies />
        <Footer />
      </Helmet>
    </>
  );
};

export default HomePage;
