import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Portfolio from "../components/Route/Portfolio";
import Helmet from "../components/Helmet/Helmet";

const PortfolioPage = () => {
  return (
    <>
      <Helmet title={"Portfolio"}>
        <Header activeHeading={2} />
        <Portfolio />
        <Footer />
      </Helmet>
    </>
  );
};

export default PortfolioPage;
