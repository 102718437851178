import React from "react";
import { projects } from "../../static/data";
import styles from "../../styles/styles";
import Categorylist from "../blog/Categorylist";

const Blog = () => {
  return (
    <section>
      <div className={`${styles.container}`}>
        <div className="px-6 mb-8">
          <h1 className="text-headingColor font-[600] text-[1.5rem] mb-5 text-center">
            Our Blog
          </h1>
          <p className="text-justify text-sm mb-6">
            This document is published and shall be construed in accordance with
            the provisions of the Information Technology (reasonable security
            practices and procedures and sensitive personal data of information)
            rules, 2011 under Information Technology Act, 2000; that require
            publishing of the Privacy Policy for collection, use, storage and
            transfer of sensitive personal data or information.
          </p>
          <div className="flex items-center justify-between flex-wrap mb-6">
            <div className="mb-7 sm:mb-0">
              <h2 className="text-headingColor font-[600] text-[1.5rem]">
                Recent Posts
              </h2>
            </div>

            <div className="flex gap-3">
              <button className="text-headingColor border border-solid border-gray-500 py-2 px-4 rounded-[6px]">
                All
              </button>
              <button className="text-headingColor border border-solid border-gray-500 py-2 px-4 rounded-[6px]">
                Past Posts
              </button>
              <button className="text-headingColor border border-solid border-gray-500 py-2 px-4 rounded-[6px]">
                Archive
              </button>
            </div>
          </div>

          <Categorylist />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {projects.map((item, index) => (
              <div
                key={index}
                className="max-w-md p-4 border border-[#e4e4e4] rounded-[8px]"
              >
                <img
                  src={item.imgUrl}
                  alt={item.alt}
                  draggable={false}
                  className="rounded-[8px]"
                />
                <h1 className="mt-3 py-2 text-xl font-bebas text-headingColor font-[500]">
                  {item.title}
                </h1>
                <p className="text-sm text-headingColor mb-5">
                  {item.description}
                </p>
                <a
                  href="/"
                  className="text-headingColor font-[400] text-[14px] border-b border-solid border-primaryColor"
                >
                  Read More
                </a>
              </div>
            ))}
          </div>
          <div className="text-center mt-12">
            <button className="text-white bg-buttonColor hover:bg-hoverColor py-2 px-4 font-[400] ease-in duration-300">
              Load More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
