import React from "react";
import styles from "../../styles/styles";
import hiringImg from "../../assets/images/hiring.jpg";

const Opportunity = () => {
  <section>
    <div className={`${styles.container}`}>
      <div className="px-6 mb-8">
        <figure>
          <img src={hiringImg} alt="" />
        </figure>
      </div>
    </div>
  </section>;
};

export default Opportunity;
