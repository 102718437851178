import React from "react";
import slack from "../../assets/images/slack.png";
import amezon from "../../assets/images/amezon.png";
import google from "../../assets/images/google.png";
import airbnb from "../../assets/images/airbnb.png";
import mongodb from "../../assets/images/mongodb.png";

const Companies = () => {
  return (
    <section className="py-6 sm:py-8 md:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h4 className="text-center text-lg font-semibold leading-8 text-primaryColor">
          Join Most Well Known Companies Around The World
        </h4>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            src={slack}
            alt=""
            className="col-span-2 w-full object-contain lg:col-span-1 max-h-[30px]"
          />

          <img
            src={google}
            alt=""
            className="col-span-2 w-full object-contain lg:col-span-1 max-h-[35px]"
          />

          <img
            src={airbnb}
            alt=""
            className="col-span-2 w-full object-contain lg:col-span-1 max-h-[35px]"
          />

          <img
            src={mongodb}
            alt=""
            className="col-span-2 w-full object-contain lg:col-span-1 max-h-[40px]"
          />

          <img
            src={amezon}
            alt=""
            className="col-span-2 w-full object-contain lg:col-span-1 max-h-[50px]"
          />
        </div>
      </div>
    </section>
  );
};

export default Companies;
