import React from "react";
import { navItems } from "../../static/data";

const Navbar = ({ active }) => {
  return (
    <>
      {navItems &&
        navItems.map((item, index) => (
          <li key={index} className="border-b border-gray-300">
            <a
              href={item.url}
              className={`${
                active === index + 1 ? "text-primaryColor text-[16px] font-[600]" : "text-primaryColor hover:text-headingColor text-[16px] font-[600]"
              }`}
            >
              {item.title}
            </a>
          </li>
        ))}
    </>
  );
};

export default Navbar;
