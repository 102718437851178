import React, { useState } from "react";
import CountUp from "react-countup";
import heroImg from "../../assets/images/web.png";
import { RiMailSendLine } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import styles from "../../styles/styles";
import { socialIcons } from "../../static/data";
import ContactModal from "../../Modal/ContactModal";

const Hero = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <section className="bg-white p-6">
      <div className="max-w-[1240px] md:flex mx-auto justify-between items-center sm:flex-col md:flex-row py-4">
        <div className="w-full md:basis-1/2">
          <h5 className="text-primaryColor font-[600] text-[18px]">
            Hello, Welcome !
          </h5>
          <h1 className="text-primaryColor font-[700] text-[30px] sm:text-[36px] leading-[35px] sm:leading-[46px] mt-5">
            Connect the technology that is the fastest.
          </h1>
          <div className="flex items-center gap-6 mt-7 text-md">
            Be the first to get the best IT deals for website apps before they
            hit the market !
          </div>
          <div className="flex items-center gap-6 mt-7">
            <div
              className={`${styles.button}`}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <RiMailSendLine />
              Hire me
            </div>
            <a
              href="portfolio"
              className="text-primaryColor font-[600] text-[16px] border-b border-solid border-primaryColor"
            >
              {" "}
              See portfolio{" "}
            </a>
          </div>
          <p className="flex gap-2 text-primaryColor mt-12 font-[500] text-[15px] leading-2 sm:pl-14 sm:pr-10">
            <span>
              <RxDashboard className="text-xl" />
            </span>
            It is not necessary that we can do everything perfectly. But there
            are some people who have special experience in a particular work and
            hence they are perfect in that work. We have seven years of
            experience in web development and we are passionate about our work.
          </p>
          <div className="flex items-center gap-9 mt-10">
            <span className="text-smallTextColor text-[15px] font-[600]">
              Follow :
            </span>

            {socialIcons.map((item, index) => (
              <span key={index}>
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 text-[20px]"
                >
                  {item.icon}
                </a>
              </span>
            ))}
          </div>
        </div>
        <div className="basis-1/2 mt-2 sm:mt-0">
          <figure className="flex items-center justify-center">
            <img src={heroImg} alt="hero" />
          </figure>
        </div>

        <div className="md:basis-1/5 flex justify-between text-center mt-10 flex-wrap gap-3 md:mt-0 md:flex-col md:justify-end md:text-end">
          <div className="mb-10">
            <h2 className="text-primaryColor font-[700] text-[32px]">
              <CountUp start={0} end={7} duration={2} suffix="+" />
            </h2>
            <h4 className="text-primaryColor font-[600] text-[18px]">
              Years of Experience
            </h4>
          </div>
          <div className="mb-10">
            <h2 className="text-primaryColor font-[700] text-[32px]">
              <CountUp start={0} end={100} duration={2} suffix="%" />
            </h2>
            <h4 className="text-primaryColor font-[600] text-[18px]">
              Success Rate
            </h4>
          </div>
          <div className="mb-10">
            <h2 className="text-primaryColor font-[700] text-[32px]">
              <CountUp start={0} end={150} duration={2} suffix="+" />
            </h2>
            <h4 className="text-primaryColor font-[600] text-[18px]">
              Happy Clients
            </h4>
          </div>
          <div className="mb-10">
            <h2 className="text-primaryColor font-[700] text-[32px]">
              <CountUp start={0} end={249} duration={2} suffix="+" />
            </h2>
            <h4 className="text-primaryColor font-[600] text-[18px]">
              Project Completed
            </h4>
          </div>
        </div>
      </div>

      {showModal && <ContactModal closeModal={setShowModal} />}
    </section>
  );
};

export default Hero;
