import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import PrivacyPolicy from "../components/Route/PrivacyPolicy";
import Helmet from "../components/Helmet/Helmet";

function PrivacyPage() {
  return (
    <>
      <Helmet title={"Privacy Policy"}>
        <Header />
        <PrivacyPolicy />
        <Footer />
      </Helmet>
    </>
  );
}

export default PrivacyPage;
