import React from "react";
import styles from "../../styles/styles";
import img1 from "../../assets/images/img3.jpg";

const Portfolio = () => {
  return (
    <section>
      <div className={`${styles.container}`}>
        <div className="px-6 mb-8">
          <h1 className="text-headingColor font-[600] text-[1.5rem] mb-5 text-center">
            Portfolio
          </h1>
          <p className="text-justify text-sm mb-6">
            We operate to the highest international standards in web development
            for small business clients in India. We are a small company and we
            are proud of it. Because this creates a desire to focus on
            individual for customer purposes. Because of this you get the
            highest quality results at affordable prices.
          </p>
          <div className="md:flex justify-between items-center">
            <div className="w-full md:w-1/2 sm:h-[450px] lg:flex items-center py-2">
              <figure className="flex items-center justify-center md:mt-8 border border-gray-300">
                <img src={img1} alt="portfolio" />
              </figure>
            </div>
            <div className="w-full md:w-1/2 sm:h-[450px]">
              <h3 className="text-headingColor font-[600] my-3 text-xl">
                We design and develop digital solutions!
              </h3>
              <p className="text-justify text-sm">
                We are a passionate, results-oriented digital marketing team
                based in India. We provide end-to-end website design,
                development and digital marketing solutions that focus on the
                goals and objectives of the customer, from strategy and design
                to internet marketing. Our team of experts provide success to
                the client on each project assigned. We support you in exceeding
                your expectations. This is the reason for our sustainability.
              </p>
              <h3 className="text-headingColor font-[600] my-3 text-xl">
                How does your business benefit online?
              </h3>
              <p className="text-justify text-sm">
                Our team analyzes and implements the latest standards and
                techniques to enable your business to keep up with the changes
                on the web that are most available for future proof dynamic
                website designs. Most importantly, we are dedicated to making
                the most of the web for your small business success.
              </p>
              <p className="text-justify text-sm">
                Websites that do not update and do not provide current
                information are a failure in the Internet sector. Websites need
                to be modified with new changes as they become outdated every
                day. Today's search engines give more preference to websites
                that are updated regularly.
              </p>
              <h3 className="text-headingColor font-[600] my-3 text-xl">
                Your satisfaction is our priority
              </h3>
              <p className="text-justify text-sm">
                We promise to meet all of your professional branding needs and
                give you the best thing at the best price. Money is not a
                priority for us, our priority is your satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
