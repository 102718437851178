import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Projects from "../components/Route/Projects";
import Helmet from "../components/Helmet/Helmet";

const ProjectsPage = () => {
  return (
    <>
      <Helmet title={"Projects"}>
        <Header activeHeading={3} />
        <Projects />
        <Footer />
      </Helmet>
    </>
  );
};

export default ProjectsPage;
