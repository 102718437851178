const styles = {
  normalFlex: "flex items-center",
  container:
    "bg-white max-w-[1240px] shadow-lg md:flex mx-auto justify-center items-start sm:flex-col md:flex-row py-8 min-h-screen",
  button:
    "bg-buttonColor text-white font-[400] flex items-center gap-2 hover:bg-hoverColor ease-in duration-300 py-2 px-4 max-h-[38px] cursor-pointer",
  heading:
    "flex items-center justify-center text-primaryColor font-[700] text-[1.2rem] sm:text-[28px] mb-2",
};

export default styles;
