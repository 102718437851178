import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import Modal from "../../Modal/Modal";
import data from "../../static/portfolioData";

const Projects = () => {
  const [nextItems, setNextItems] = useState(6);
  const [portfolios, setPortfolios] = useState(data);
  const [selectTab, setSelectTab] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [activeID, setActiveID] = useState(null);

  const loadMoreHandler = () => {
    setNextItems((prev) => prev + 3);
  };

  const showModalHandler = (id) => {
    setShowModal(true);
    setActiveID(id);
  };

  useEffect(() => {
    if (selectTab === "all") {
      setPortfolios(data);
    }
    if (selectTab === "web-design") {
      const filteredData = data.filter(
        (item) => item.category === "Web Design"
      );
      setPortfolios(filteredData);
    }
    if (selectTab === "ux-design") {
      const filteredData = data.filter((item) => item.category === "Ux");
      setPortfolios(filteredData);
    }
  }, [selectTab]);

  return (
    <section>
      <div className={`${styles.container}`}>
        <div className="px-6 mb-8">
          <h1 className="text-headingColor font-[600] text-[1.5rem] mb-5 text-center">
            Our Projects
          </h1>
          <p className="text-justify text-sm mb-4">
          We work on our own projects in a certain way, which includes beauty, smoothness and speed from every website. The website we build is tested to see if it is working properly on mobile and web versions. And the most important thing is whether the data access is working properly or not. Then it is corrected.
          </p>

          <div className="flex items-center justify-between flex-wrap mb-6">
            <div className="mb-7 sm:mb-0">
              <h3 className="text-headingColor text-[1.5rem] font-[600]">
                Recent projects
              </h3>
            </div>

            <div className="flex gap-3">
              <button
                onClick={() => setSelectTab("all")}
                className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[6px]"
              >
                All
              </button>
              <button
                onClick={() => setSelectTab("web-design")}
                className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[6px]"
              >
                Web Design
              </button>
              <button
                onClick={() => setSelectTab("ux-design")}
                className="text-smallTextColor border border-solid border-smallTextColor py-2 px-4 rounded-[6px]"
              >
                UX Design
              </button>
            </div>
          </div>

          <div className="flex items-center gap-4 flex-wrap mt-12">
            {portfolios?.slice(0, nextItems)?.map((portfolio, index) => (
              <div
                key={index}
                data-aos="fade-zoom-in"
                data-aos-delay="50"
                data-aos-duration="1000"
                className="group max-w-full sm:w-[48.5%] md:w-[31.8%] lg:w-[32.2%] relative z-[1]"
              >
                <figure>
                  <img
                    className="rounded-[8px]"
                    src={portfolio.imgUrl}
                    alt=""
                  />
                </figure>

                <div className="w-full h-full bg-primaryColor bg-opacity-40 absolute top-0 left-0 z-[5] hidden group-hover:block">
                  <div className="w-full h-full flex items-center justify-center">
                    <button
                      onClick={() => showModalHandler(portfolio.id)}
                      className="text-white bg-activeColor hover:bg-activeColor py-2 px-4 font-[400] ease-in duration-300"
                    >
                      See Details
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            {nextItems < portfolios.length && data.length > 6 && (
              <button
                onClick={loadMoreHandler}
                className="text-white bg-buttonColor hover:bg-hoverColor py-2 px-4 font-[400] ease-in duration-300"
              >
                Load More
              </button>
            )}
          </div>
        </div>
      </div>
      {showModal && <Modal setShowModal={setShowModal} activeID={activeID} />}
    </section>
  );
};

export default Projects;
