import React from "react";
import styles from "../../styles/styles";
import { services } from "../../static/data";

const Services = () => {
  return (
    <section>
      <div className={`${styles.container}`}>
        <div className="px-6 mb-8">
          <h1 className="text-headingColor font-[600] text-[1.5rem] mb-5 text-center">
            Our Services
          </h1>
          <p className="text-justify text-sm mb-6">
            Given below are some of the specialized work done by us. Although
            all the work related to information and technology is possible for
            us, but apart from web development, we also do print media
            advertisements. For this we have tied up with Times of India, Dainik
            Jagran, Dainik Hindustan, Amar Ujala. Through these we can publish
            all kinds of advertisements across the country.
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {services.map((item, index) => (
              <div
                key={index}
                className="bg-bgColor p-6 relative mt-16 group hover:bg-gray-200 cursor-pointer ease-in duration-150"
              >
                <h4 className="text-primaryColor font-medium text-xl mt-8 mb-4 group-hover:text-blue-950">
                  {item.title}
                </h4>
                <p className="text-sm text-headingColor group-hover:text-headingColor">
                  {item.subtitle}
                </p>
                <div className="p-4 rounded-full bg-buttonColor w-20 h-20 absolute -top-10 shadow-md flex justify-center items-center group-hover:bg-primaryColor">
                  <div className="text-3xl text-white group-hover:text-white">
                    {item.icon}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

// {services.map((item, index) => (
//  <div key={index}>
//    <span>
//      <i class={item.icon}></i>
//    </span>
//   <h4 className="text-white font-medium text-xl mt-8 mb-4 group-hover:text-blue-950">{item.title}</h4>

//   <p className="text-sm text-gray-400 group-hover:text-gray-600">{item.subtitle}</p>
// </div>
// ))}
