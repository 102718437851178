import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillGithub,
} from "react-icons/ai";

import { FiShoppingCart } from "react-icons/fi";
import {BiCodeAlt, BiBuildingHouse, BiStoreAlt, BiBarcodeReader, BiBookAdd } from "react-icons/bi";

// social icons
export const socialIcons = [
  {
    name: "Facebook",
    icon: <AiFillFacebook />,
    url: "https://facebook.com/earthcorp7",
  },
  {
    name: "Twitter",
    icon: <AiOutlineTwitter />,
    url: "https://twitter.com/earthcorpltd",
  },
  {
    name: "Instagram",
    icon: <AiFillInstagram />,
    url: "https://instagram.com/earthcorp7",
  },
  {
    name: "GitHub",
    icon: <AiFillGithub />,
    url: "https://github.com/Earthcorp",
  },
];

// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Portfolio",
    url: "/portfolio",
  },
  {
    title: "Projects",
    url: "/projects",
  },
  {
    title: "Services",
    url: "/services",
  },
  {
    title: "Contact",
    url: "/contact",
  },
  {
    title: "Blog",
    url: "/blog",
  },
  {
    title: "Opportunity",
    url: "/opportunity",
  },
];

// projects
export const projects = [
  {
    id: "01",
    imgUrl:
      "https://navbharattimes.indiatimes.com/thumb/89071509/running-shoes-89071509.jpg?imgsize=93890&width=540&height=405&resizemode=75",
    alt: "shoes",
    category: "Web Design",
    title: "Frontend",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus eaque deleniti debitis",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  },
  {
    id: "02",
    imgUrl:
      "https://navbharattimes.indiatimes.com/thumb/89071509/running-shoes-89071509.jpg?imgsize=93890&width=540&height=405&resizemode=75",
    alt: "shoes",
    category: "Web Design",
    title: "Backend",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus eaque deleniti debitis",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  },
  {
    id: "03",
    imgUrl:
      "https://navbharattimes.indiatimes.com/thumb/89071509/running-shoes-89071509.jpg?imgsize=93890&width=540&height=405&resizemode=75",
    alt: "shoes",
    category: "Ux",
    title: "UI/UX Design",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus eaque deleniti debitis",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  },
  {
    id: "04",
    imgUrl:
      "https://navbharattimes.indiatimes.com/thumb/89071509/running-shoes-89071509.jpg?imgsize=93890&width=540&height=405&resizemode=75",
    alt: "shoes",
    category: "Ux",
    title: "UI/UX Design",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus eaque deleniti debitis",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  },
  {
    id: "05",
    imgUrl:
      "https://navbharattimes.indiatimes.com/thumb/89071509/running-shoes-89071509.jpg?imgsize=93890&width=540&height=405&resizemode=75",
    alt: "shoes",
    category: "Ux",
    title: "UI/UX Design",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus eaque deleniti debitis",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  },
  {
    id: "06",
    imgUrl:
      "https://navbharattimes.indiatimes.com/thumb/89071509/running-shoes-89071509.jpg?imgsize=93890&width=540&height=405&resizemode=75",
    alt: "shoes",
    category: "Ux",
    title: "UI/UX Design",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus eaque deleniti debitis",
    technologies: ["React", "Tailwind css", "Node.js", "MongoDB"],
  },
];

// services
export const services = [
  {
    icon: <FiShoppingCart />,
    title: "eCommerce Website",
    subtitle:
      "Take advantage of the World Wide Web, by transforming your shop into an ecommerce website. With the help of our innovative ecommerce website features, you can retain the number of online customers.",
    bg: "#E0FFFF",
  },
  {
    icon: <BiStoreAlt />,
    title: "Business Website",
    subtitle:
      "Achieve your business goals with our flawless business website. Our developers help your business by building scalable websites that reflects your business standards. With a business website, you can retain your professional image, maintain strong clientele base.",
    bg: "#FFEFD5",
  },
  {
    icon: <BiBuildingHouse />,
    title: "Real Estate Website",
    subtitle:
      "Beat the competition with our innovative real estate website. Help your customers to discover their home virtually, or help your investors find the best deal at their finger tips with a seamless real estate website.",
    bg: "#EBF4FA",
  },
  {
    icon: <BiBarcodeReader />,
    title: "On-Demand Website",
    subtitle:
      "With the rising trend of on-demand streaming, we help you to create uninterrupted entertainment with an on-demand OTT website. With our smart on-demand website features, you can beat the competition and take an advantage by retaining the attention of your viewers.",
    bg: "#FFF0F5",
  },
  {
    icon: <BiCodeAlt />,
    title: "e-Learning Website",
    subtitle:
      "Get the latest trend of education and learning with our e-Learning websites. Create interactive learning experiences with our creative websites, that engage your students online.",
    bg: "#CCFFCC",
  },
  {
    icon: <BiBookAdd />,
    title: "Healthcare Website",
    subtitle:
      "Take your healthcare brand to next level with an on-the-go healthcare website. Having a healthcare website is the best way to bring doctors and patients on digital platform and deliver remote treatment with ease.",
    bg: "#FFFFCC",
  },
];
